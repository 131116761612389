import axios from 'axios'

const ax = axios.create({
  baseURL: `https://${window.pageData.siteWide.hostname}/api/register/`
})

export default {
  createOrUpdateReservation: async (slug, uniqueId, reservation, customFieldSet = null) => {
    let url

    if (uniqueId) {
      url = `${slug}/${uniqueId}`
    } else {
      url = slug
    }

    return await ax.post(url, { reservation, customFieldSet })
  },
  uploadSubjectPhoto: (slug, uniqueId, subjectPhoto) => {
    const formData = new FormData()

    formData.append('subjectPhoto', subjectPhoto)

    return ax.post(
      `${slug}/${uniqueId}/photo`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }
}
