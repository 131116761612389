<template>
  <div>
    <div
      v-if="eventSettings.logoUrl"
      class="text-center"
    >
      <img
        :src="eventSettings.logoUrl"
        :alt="eventSettings.Name"
        class="img-event-logo mb-2"
      >
    </div>
    <h1
      v-if="!eventSettings.logoUrl"
      class="text-center"
    >
      {{ eventSettings.name }}
    </h1>
    <h2
      v-if="eventSettings.subTitle"
      class="registration-index__sub-head text-center"
    >
      {{ eventSettings.subTitle }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'EventHeader',
  computed: {
    eventSettings () {
      return this.$store.state.viewModel.eventSettings
    }
  }
}
</script>
